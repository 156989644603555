<template>
  <div id="app">
    <router-view />
    <!-- <iframe src="../public/static/douyin_agreement_privacy.html" frameborder="0" style="width:100%;height:100%"></iframe> -->
  </div>
</template>

<script>
import { Local } from '@/utils/storage';
export default {
  name: 'App',
  components: {
  },
  created() {
    document.title = '才高八斗';
    Local.set('lang', {lang: 'zh'})
  },
}
</script>

<style>
#app{
  font-size: 14px;
  /* min-width: 100vw;
  min-height: 100vh; */
}
</style>
