export default {
	router: {
		aboutUs: '关于我们',
		oroduct: '产品介绍',
		CRM_system: 'CRM系统',
		copyTradeSystem: '跟单系统',
		liquidityClearing: '流动性清算系统',
		webTrader: '经纪商终端',
		intelligentAnalysisSystem: '智能分析系统',
		plug_in: '插件',
		MT45_Solutions:'MT4/MT5解决方案',
		MT45_PlatformBuilding: '外汇平台搭建',
		websiteBuilding: '金融网站建设',
		riskManagement_Maintenance: '运维风险',
		EA_Leasing: 'EA租赁',
		enWebsiteBuilding: '网站建设',
		whiteLabelConstruction: 'White label construction',
		serverLease: 'Server lease',
		offshoreCompanyRegistration: 'Offshore company registration',
		paymentMethod: 'Payment method',
		applicationFinancialLicence: 'Application for financial licence',
		cooperation: '商务合作',
		technicalCooperation: '技术合作',
		productAgent: '产品代理',
		businessExpansion: '业务合作',
		partner: '合作伙伴',
		joinUs: '加入我们',
		contactUs: '联系我们'
	},
	footer: {
		aboutUs: '关于我们',
		oroduct: '产品介绍',
		MT45_Solutions:'MT4/MT5解决方案',
		cooperation: '商务合作',
		partner: '合作伙伴',
		contactUs: '联系我们',
		p1: '上海三点网络科技有限责任公司是一家以技术研发和技术咨询服务为主要业务的高科技企业。本公司只提供软件产品和技术服务，不以任何形式接受客户资金，也不开展任何金融产品的经纪业务。',
		copyright : '版权所有 2024 - 上海三点网络科技有限责任公司'
	}
};