import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Local } from '@/utils/storage';

import zhcnLocale from 'element-ui/lib/locale/lang/zh-CN';
import enLocale from 'element-ui/lib/locale/lang/en';

import nextZhcn from '@/i18n/lang/zh-cn.js';
import nextEn from '@/i18n/lang/en.js';

import pagesHomeZhcn from '@/i18n/pages/home/zh-cn.js';
import pagesHomeEn from '@/i18n/pages/home/en.js';

// 使用插件
Vue.use(VueI18n);

// 定义语言国际化内容
/**
 * 说明：
 * /src/i18n/lang 下的 js 为框架的国际化内容
 * /src/i18n/pages 下的 js 为各界面的国际化内容
 */
 const messages = {
	zh: {
		...zhcnLocale,
		message: {
			...nextZhcn
		},
		...pagesHomeZhcn,
	},
	en: {
		...enLocale,
		message: {
			...nextEn
		},
		...pagesHomeEn,
	}	
};

// 导出语言国际化
export const i18n = new VueI18n({
	locale: Local.get('lang') ? Local.get('lang').lang : 'zh',
	fallbackLocale: 'zh',
	messages,
});

// console.log(i18n.tc("message.app.youLoggedOut"))
// 重新封装方法
export function $t(args) {
	return i18n.tc.call(i18n, args);
}
