import Vue from 'vue';

import VueRouter from 'vue-router'
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/layout/index.vue'),
    redirect: '/home/index',
    children: [
      {
        path: '/home/index',
        component: () => import('@/view/home/index.vue'),
      },
    ]
  },
]

// 加载静态路由
const createRouter = () =>
	new VueRouter({
		routes: routes,
	});

// 创建路由
const router = createRouter();

// 导出路由
export default router;