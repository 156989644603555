export default {
  home: {
    technologywealth: '科技创造财富',
    worldIntelligentTradingPlatform: '打造全球智能交易平台',
    tradingPlatforms_Hippo: 'The World’s Most Popular Trading Platforms Combined with Hippo Technology',
    metaTrader: 'MetaTrader 4/5',
    forexBrokers: 'Managing client relations for Forex brokers',
    productExplain: '产品介绍',
    aboutUs: '关于我们',
    mt4_5: 'MT4/5',
    intelligentlyCopyOrders: '智能跟单社区搭建',
    intelligentlyCopyOrders1: '发展智能跟单社区，无IB模式业务拓展是经纪商未来的发展方向！',
    intelligentlyCopyOrders2: 'e投睿(eToro)通过跟单社区进行信号分享模式实现全球180个国家600万客户规模。IC Market通过跟单模式在无IB的情况下实现每年数百亿美金的业务量。 给客户提供一个良好的技术交流平台，充分发挥投资者的优势。',
    intelligentlyCopyOrders3: '提升平台形象、提高平台服务质量、节约客户开发成本。',
    learnMore: '点击查看',
    PlatformBuilding: '平台搭建',
    Hippo_PlatformBuilding: 'HIPPO拥有专业的技术团队，帮助国际上多家外汇经纪商搭建平台。',
    crmSystem: 'CRM系统',
    HIPPO_CRM_crmSystem: 'HIPPO-CRM系统是为外汇经纪商量身打造的客户关系管理系统，无缝对接MT4/5/MT5，可在线完成用户开户及入金等券商基础账户管理业务，同时智能、灵活的自动返佣系统，可以帮助零售商轻松建立多层级佣金结算体系，方便IB管理客户与下级IB。',
    managementMaintenance: '风控运维',
    managementMaintenance_div: '经纪商平台安全稳定运行 是为客户提供良好交易环境的基础。风控是外汇经纪商必不可少的部门。然而，由于其技术含量高，只有经验丰富的专业技术人员才担当此大任。',
    professionalDevelopmentTeam: 'WE ARE PROFESSIONAL DEVELOPMENT TEAM',
    professionalFinancialServicesCompany: '我们是一家专业的金融服务平台',
    continuousInnovation: '我们以用户体验与服务创新为客户带来更好的产品和商业的革新，创造新价值',
    HIPPO_copyOrderSystem: 'HIPPO 跟单社区',
    HIPPO_copyOrderSystem1: '云信号跟单社区',
    HIPPO_copyOrderSystem2: '单一平台跟单社区',
    HIPPO_copyOrderSystem3: 'EA跟单系统',
    HIPPO_copyOrderSystem4: 'MT4/5全面支持，不漏单',
    HIPPO_CRM: 'HIPPO CRM 系统',
    HIPPO_CRM1: '多层次返佣系统',
    HIPPO_CRM2: 'AB仓对冲系统',
    HIPPO_CRM3: '赠金配资系统',
    HIPPO_CRM4: '为经纪商量身定制专业的CRM',
    liquidityClearingSystem: '流动性清算系统',
    liquidityClearingSystem1: 'WebTrader清算终端',
    liquidityClearingSystem2: 'BrokerPortal经纪商终端',
    liquidityClearingSystem3: '发展白标必备清算系统',
    liquidityClearingSystem4: '流量统计',
    learnMore2: '查看详情',
    brokerSolutions: '经纪商解决方案',
    brokerSolutions_p: '综合全球十大流动性提供商和迈达克公司为客户提供全方位的一站式服务。',
    CRM_system: 'CRM系统',
    CRM_system_p: '根据客户需求完全定制化服务的CRM系统，多层次返佣即时到账。',
    MT45_maintenance: '风控运维',
    MT45_maintenance_p: '24小时+经验丰富的高级分析师提供安全的风控服务。',
    EA_system: 'EA智能交易系统',
    EA_system_p: '10年实盘交易，打造国际最稳定的EA系统。',
    MAM_system: 'MAM软件',
    MAM_system_p: '帮助平台发展管理客户提供现进的MAM软件，让多账户交易、跟单等轻松实现。',
    analysisSystem: 'Analysis System',
    analysisSystem_p: 'The analysis system is a right-hand assistant for the traders on the way to profit.',
    technicalTraining: '技术培训',
    technicalTraining_p: '为金融机构提供风控、运维等技术培训，保障平台的安全稳定运行。',
  }  
}